/* banner section css start*/
.bannerbg {
  background-image: linear-gradient(
    265deg,
    #523578 1.03%,
    #d32864 51.26%,
    #f59433 93.99%
  );
  padding: 70px 0px;
}

.headingbg {
  background-image: url("/public/images/Policy/Security\ and\ Trust.png");
  height: 66px;
  background-size: contain;
  background-repeat: no-repeat;
}

.headingbg h1 {
  margin: 0px;
  padding-left: 5px;
  padding-top: 29px;
  color: #fff8f5;
  font-size: 48px;
  font-weight: 700;
  line-height: 41px;
  padding-top: 14px;
}

/* banner section css end*/
.securityBox {
  position: relative;
}
/* box css */
.securityBox:hover h2,
.securityBox:hover p {
  color: #fff;
}
.securityBox:hover {
  transform: scale(1.02);
  transition: all 0.5s ease-in-out;
  background: linear-gradient(
    137.51deg,
    #583477 -11.87%,
    #c42a66 47.28%,
    #f38d36 111.76%
  );
}

.securityBox {
  box-shadow: 0px 0px 8px 0.5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-bottom: 40px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}
.securityBox h2 {
  font-size: 35px;
  color: #07242f;
  font-weight: 600;
}
.securityBox p {
  color: #6c6c72;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 25px;
}
.securityBox a {
  background: #c72966;
  border: none;
  padding: 10px 18px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
}
.securityBox a:hover {
  background-color: #f59433;
  transform: scale(0.9);
  transition: all 0.5s ease-in-out;
}
.security {
  margin-top: 6rem;
  margin-bottom: 12rem;
}
.secBox {
  margin-bottom: 25px;
}
/* box css */
@media (min-width: 170px) and (max-width: 991px) {
  .security {
    margin-top: 4rem;
    margin-bottom: 7rem;
  }
  .securityBox h2 {
    font-size: 28px;
    color: #07242f;
    font-weight: 600;
  }
  .securityBox p {
    color: #6c6c72;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 25px;
  }
  .headingbg h1 {
    margin: 0px;
    padding-left: 5px;
    padding-top: 29px;
    color: #fff8f5;
    font-size: 31px;
    font-weight: 700;
    line-height: 32px;
    padding-top: 14px;
  }
}
