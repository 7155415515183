/* banner section css start*/
.bannerbg {
  background-image: linear-gradient(
    265deg,
    #523578 1.03%,
    #d32864 51.26%,
    #f59433 93.99%
  );
  padding: 102px 0px;
}
.heroimg {
  margin-top: 10px;
}
.headingbg {
  background-image: url("/public/images/blogs/bLogS.png");
  height: 77px;
  background-size: c;
  background-repeat: no-repeat;
}

.headingbg h1 {
  margin: 0px;
  padding-left: 5px;
  padding-top: 29px;
  color: #fff8f5;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}

/* banner section css end*/
/* navigation section  */
.bredCrump {
  border-bottom: 1px solid #000;
}

.bredCrump a,
.bredCrump p,
.heroHeading a,
.sideBlog a {
  text-decoration: none;
  color: #5f3476;
  font-size: 18px;
  font-weight: 600;
}

.heroHeading h2 {
  font-size: 32px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
}

.heroHeading p {
  font-size: 15px;
  font-weight: 400;
  color: #6c6c72;
  line-height: 2;
}

.heroHeading .butt {
  background-color: #d32864 !important;
  border: none;
  border-radius: 0px;
  color: #fff8f5;
  font-size: 15px;
  font-weight: 400;
}

.sideBlog h3 {
  font-size: 18px;
  font-weight: 600;
}

.sideBlog .sidebt {
  margin-top: 8px;
  background-color: #d32864 !important;
  border: none;
  border-radius: 0px;
  color: #fff8f5;
  font-size: 15px;
  font-weight: 400;
}

.sideBlog img {
  width: 135px;
  height: 130px;
}

.fullBackground {
  background-color: #ffffff;
}

.sideBlog {
  border-bottom: 2px solid #d9d9d9;
  padding: 10px 0px;
}

.largBlog {
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
}

.viewAll {
  margin-bottom: 25px;
  margin-top: 25px;
}
.viewAll .viewBtn {
  background-color: #d32864 !important;
  border: none;
  border-radius: 0px;
  color: #fff8f5;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 30px;
}
.vBtn {
  text-align: end;
}
.viewAll a,
.viewAll p {
  text-decoration: none;
  color: #5f3476;
  font-size: 18px;
  font-weight: 600;
}
.cardBlog {
  background: #f3f3f3;
  box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.25);
  padding-bottom: 30px;
}
.cardBlog .cardBtn {
  background-color: #d32864 !important;
  border: none;
  border-radius: 0px;
  color: #fff8f5;
  font-size: 15px;
  font-weight: 400;
}

.cardText {
  padding-left: 20px;
  margin-top: 20px;
  padding-right: 10px;
}
.cardBlog img {
  width: 100%;
}
.cardText h2 {
  font-size: 18px;
  font-weight: 600;
}
.cardText p {
  color: #6c6c72;
  font-weight: 400px;
  margin-top: 8px;
}
.cardBlog a {
  text-decoration: none;
  color: #5f3476;
  font-size: 18px;
  font-weight: 600;
}
.cardBlog {
  margin-bottom: 20px !important ;
}
/* navigation Section  */

/* get in touch */

.getIntouch {
  background-color: #f4f4f4;
}

.contactForm {
  height: auto;
  background-size: cover;
  padding: 40px 60px;
  border-radius: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contactMail h5 {
  font-size: 45px;
  color: #333333;
  font-weight: 600;
}

.contactMail p {
  font-size: 20px;
  color: #6c6c72;
  margin-bottom: 30px;
  font-weight: 400;
}
.ico_get,
.ico_get2 {
  position: relative;
}
.mainForm {
  padding: 14px 14px 14px 23px;
  border-radius: 0px;
  border-color: #f4f4f4;
  border-bottom: 1.5px solid #b5b5b5 !important;
  background-color: #f4f4f4;
}
/* .btn {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.btn .formbtn {
  background-color: #f59435;
  border: 0px !important;
  border-radius: 0px !important;
  padding: 12px 47px;
  margin-top: 25px;
  font-size: 22px;
}
.textareaForm {
  border-radius: 0px;
  border-color: #f4f4f4;
  border-bottom: 1.5px solid #b5b5b5 !important;
  padding: 15px 0px 0px 20px;
  background-color: #f4f4f4;
}
.textareaForm::placeholder {
  font-size: 20px;
  font-weight: 500;
  color: #6c6c72;
}
.mainForm {
  font-size: 20px;
  font-weight: 500;
  color: #6c6c72;
}
.getInToch {
  background-color: #f4f4f4;
}
.ico_get svg {
  position: absolute;
  top: 15px;
  left: -4px;
  z-index: 10;
  height: 30px;
  width: 22px;
}
.ico_get2 svg {
  position: absolute;
  top: 21px;
  left: 6px;
  z-index: 10;
  height: 30px;
  width: 22px;
  color: #6c6c72;
}
.getIn_img img {
  width: 90%;
}
.getIn_img {
  text-align: left;
}
.findsectionbg {
  background: url(../../../public/images/home/findbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 20px;
  text-align: center;
  margin-top: 100px;
  border-radius: 30px;
}
.findtextarea h6 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  color: white;
  letter-spacing: 0em;
  text-align: center;
}
.bg {
  margin-top: 122px;
}
.findtextarea p {
  margin-top: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  color: #fff8f5;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 0.9;
}

.findbtn {
  margin-top: 10px;
  background: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #c72966;
  padding: 15px 30px;
}
.findbtn:hover {
  background: #f0833b;
  border-color: #ef813b;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* get in touch */

@media (max-width: 768px) {
  .heroimg img {
    width: 100%;
  }
  .bannerbg {
    padding: 30px 0px;
  }
  .contactForm {
    height: auto;
    background-size: cover;
    padding: 10px 10px;
    border-radius: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
