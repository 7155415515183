.ourcleintbg {
  background: #5f3376;
  padding: 50px 0px !important;
  margin-bottom: 50px !important;
  overflow: hidden;
}

.clientheading h5 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #f59433;
}
.clientheading p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff8f5;
  opacity: 0.7;
}
.cleintbox {
  border-radius: 17px;
  background: #ffffff;
  margin: 20px;
  /* max-width: 440px; */
  padding: 20px;
}
.boxflex {
  display: flex;
}
.cleintbox p {
  margin-top: 15px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #6c6c72;
}
.cleintbox h2 {
  color: #6c6c72;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.cleintbox h6 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  margin: 0px !important;

  letter-spacing: 0em;
  text-align: left;
}

.marquee_inner span {
  /* float:left; */
  width: 50%;
}
.marquee_wrapper .marquee_block {
  --total-marquee-items: 6;
  height: 398px;
  width: calc(312px * (var(--total-marquee-items)));
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  margin: 20px auto;
  /* background:#1B2531; */
  padding: 30px 0;
}
.marquee_inner {
  display: block;
  width: 200%;
  position: absolute;
}
.marquee_inner p {
  font-weight: 500;
  font-size: 16px;
}
.marquee_inner.to_left {
  animation: marqueeLeft 25s linear infinite;
}
.marquee_inner.to_right {
  animation: marqueeRight 25s linear infinite;
}
.marquee_item {
  width: 500px;
  height: auto;
  display: inline-block;
  margin: 0 10px;
  float: left;
  transition: all 0.2s ease-out;
}
@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marqueeRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@media (max-width: 768px) {
  .clientheading h5 {
    font-size: 21px;
    line-height: 0px;
  }
  .marquee_item {
    margin: 0px 0px;
  }
  .marquee_wrapper .marquee_block {
    height: 550px;
  }
  .marquee_inner.to_left {
    animation: marqueeLeft 40s linear infinite;
    -webkit-animation: marqueeLeft 40s linear infinite;
  }
  .marquee_inner.to_right {
    animation: marqueeRight 40s linear infinite;
    -webkit-animation: marqueeRight 40s linear infinite;
  }

  .marquee_item {
    width: 300px;
  }

  .clientheading p {
    font-family: Poppins;
    font-size: 14px;

    line-height: 25px;
  }
}
