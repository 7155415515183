/* .service_section {
    background: #5d3476;
    padding: 60px 0;
    /* padding-left: 15%; */
/* right: 200; */
/* }  */
.services_info {
  text-align: center;
}

.services_info span {
  display: block;
  margin-top: -17px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}
.main_box .servbox1 {
  overflow: visible !important;
}
.services_info h4 {
  color: #fff;
  font-size: 48px;
}

.services_info p {
  color: #fff;
  font-size: 16px;
}
.servbox {
  border-radius: 17px;
  background: #f59435;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.25);
  padding: 22px;
  width: 352px;
  text-align: left;
  margin-bottom: 50px;
}
.servbox img {
  width: 20%;
}
.servbox img {
  width: 50px;
  height: 50px;
}

.servbox li {
  color: #cfcfcf;
  font-size: 18px;
  position: relative;
}
.servbox li:hover:before {
  position: absolute;
  content: "";
  width: 50%;
  height: 2px;
  background-color: rgb(255, 255, 255);
  bottom: -3px;
}

.servbox ul {
  padding-left: 20px;
}

.servbox h3 {
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 25px;
}

.servbox p {
  color: #d53762;
  font-size: 20px;
  border-bottom: 1px solid;
}

.main_box {
  display: flex;
  overflow: auto;
  padding: 0px 16px;
}

.servbox {
  overflow: visible;
  position: relative;
  margin-top: 41px;
  position: relative;
  margin-right: -206px;
  /* position: revert; */
}
.servbox:hover {
  margin-left: 0;
  transition: 0.7s ease;
  transition: 0.3s;
  background: #ffffff;
  position: relative;
  transform: translate(-0.5rem, -1rem) rotate(3deg);
}
.servbox:hover h3 {
  color: #000;
}
.servbox:hover p {
  color: #fff;
}
.servbox:hover p {
  color: #000;
}
.outs {
  padding-top: 100px;
}

.outs h4 {
  font-size: 38px;
  color: #fff;
}

.outs p {
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
}

.service_section {
  ::-webkit-scrollbar {
    width: 90% !important;
    background-image: linear-gradient(
      360deg,
      #523578 1.03%,
      #d32864 51.26%,
      #f59433 93.99%
    );
    /* background-color: #0a060c !important; */
    padding: 10px;
    border-radius: 20px;
    height: 10px;

    /* border-right: 250px solid yellow !important; */
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: auto;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    background-color: #dbdbdb;

    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-right: 200px;
    margin-left: 200px;
  }

  ::-webkit-scrollbar-thumb {
    border: 3px solid transparent !important;
    background-clip: content-box;
    -webkit-border-radius: 20px;
    border-radius: 20px;

    -webkit-box-shadow: auto;
    /* background-color: #e76747 !important; */
    background-image: linear-gradient(
      360deg,
      #523578 1.03%,
      #d32864 51.26%,
      #f59433 93.99%
    );

    border-radius: x 20p;
    -moz-border-radius: x 20p;
    -ms-border-radius: x 20p;
    -o-border-radius: x 20p;
  }
}

@media (max-width: 767px) {
  ::-webkit-scrollbar {
    display: none !important;
  }

  .service_section {
    ::-webkit-scrollbar {
      display: none !important;
    }
  }

  .servbox {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}
