/* banner section css start*/
.bannerbg {
  background-image: linear-gradient(
    265deg,
    #523578 1.03%,
    #d32864 51.26%,
    #f59433 93.99%
  );
  padding: 150px 0px;
}

.headingbg {
  background-image: url("/public/images/industrymain/Industries.png");
  height: 64px;
  background-size: contain;
  background-repeat: no-repeat;
}

.headingbg h1 {
  margin: 0px;
  padding-top: 13px;
  color: #fff8f5;
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
}

/* banner section css end*/

/* side section css start*/

/* side section css start*/

.sidebg {
  background: #fafafa;
  margin-top: 30px;
  padding: 40px 0px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
}

.sideheading {
  padding: 0px 30px;
}

.sideheading h6 {
  color: #121212;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.69px;
}

.sideheading hr {
  margin-top: 20px;
  border: 0 !important;
  height: 4px !important;
  background: linear-gradient(
    90deg,
    #f59433 0 21%,
    #f59433 19% 38%,
    #d9d9d9 34% 80%
  );
  opacity: 1 !important;
}

.headerbg {
  padding: 0px;
  border: none;
}

.headerbg1 {
  border: none;
}

.headercolor::after {
  content: ">";
  right: 42px;
  top: 16px;
  position: absolute;
  font-size: 22px;
  color: #d7d7d7;
  z-index: 55555;
}

.headercolor {
  position: relative;
  background: #fafafa;
  padding: 0px 30px;
  border: none;
  border-radius: 0px;
}

.headerbg hr {
  height: 2px;
  background: #6e6e6e;
  margin: 0 auto;
  border: none;
}

.headerbg button {
  padding: 20px 0px;
  background: none;
  color: #828282;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.7px;
  border: none;
}

.iconimg {
  height: 40px;
  width: 40px;
}

.iconbox h1 {
  border-bottom: 2px solid #d9d9d9;
  color: #121212;
  font-size: 14px;
  margin: 0 30px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 23px;
  padding: 15px 0;
}

.listbodybg {
  background: #fafafa;
  padding: 0px;
}

.headercolor:hover .headerbg hr {
  color: #f59433 !important;
}

.headercolor:hover {
  background: #f59433;
}

.headercolor:hover button {
  color: #fff;
}

.inputslide {
  background: #efefef;
  border-radius: 0px;
  font-size: 12px;
  border: none;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-bottom: 15px;
  padding: 15px 10px;
}

.inputslide::placeholder {
  color: #c6c6c8;
}

.textareas::placeholder {
  font-size: 12px;
  color: #c6c6c8;
  font-weight: 400;
}

.iconimg {
  width: 40px;
  height: 40px;
}

.textareas {
  background: #efefef;
  width: 100%;
  border: none;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 0px;
  height: 100px;
}

.formbtn {
  margin-top: 15px;
  color: #fff8f5;
  font-size: 13.015px;
  padding: 10px 30px;
  font-weight: 500;
  border: none;
  line-height: normal;
  border-radius: 0.868px;
  background: #f59435;
}

.formboxpadding {
  padding: 0px 30px;
}

/* side section css end*/

/* right section css start*/
/* outsourcing section css start*/
.outsourcing h1,
.typesservices h1,
.steps h1 {
  color: #07242f;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-transform: capitalize;
}

.outsourcing {
  margin-top: 30px;
  padding-left: 10px;
}

.herosection img {
  width: 100%;
  object-fit: cover;
  height: 375px;
}

.herotext p,
.typesservices p,
.steps p {
  color: #3c3c3c;
  font-size: 20px;
  margin: 0px;

  font-weight: 400;
  line-height: 29px;
}

/* outsourcing section css end*/
/* typesservices section css start*/
.typesservices {
  margin-top: 50px;
  background: #f4f4f4;
  padding: 50px 18px 50px 18px;
}

.typebox {
  margin-top: 25px;
  background-color: #f59433;
  padding: 40px 18px;
}

.typebox h2,
.typebox2nd h2,
.typebox3rd h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
}

.typebox p,
.typebox2nd p,
.typebox3rd p {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
}

.typebox2nd {
  background: #5f3376;
  padding: 40px 18px;
  margin-top: 25px;
}

.typebox3rd {
  margin-top: 25px;
  background: #c72966;
  padding: 40px 18px;
}

.typeboxbg {
  margin-top: 25px;
  background-image: url("/public/images/industrymain/typebg.webp");
  padding: 60px 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.typeboxbg h1 {
  color: #fff8f5;
  text-align: center;
  font-size: 44px;
  font-weight: 500;
  line-height: 51px;
}

.typeboxbg p {
  color: #fff;
  text-align: center;
  font-size: 20px;

  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.4px;
}

.typebtn {
  border-radius: 1.319px;
  background: #c72966;
  color: #fff8f5;
  font-size: 19.791px;
  display: block;
  margin: 30px auto 0px;
  font-weight: 400;
  line-height: normal;
  border: none;
  padding: 10px 20px;
}
.typebtn:hover {
  background: #f59435;
}

/* typesservices section css end*/
/* step section css start*/
.steps {
  margin: 40px 0;
}

.stepboxbg {
  margin-top: 40px;
  position: relative;
  padding: 40px 30px;
  display: inline-block;
}

.stepboxbg::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 3px;
  /* control the border thickness */
  background: linear-gradient(
    141.07deg,
    #f59433 1.84%,
    #cf2865 47.77%,
    #5c3477 99.59%
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  /* mask-composite: exclude; */
  /* pointer-events: none; */
}

.stepboxbg h1 {
  margin-top: 15px;
  color: #07242f;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
}

.stepboxbg p {
  color: #6c6c72;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}

/* step section css end*/

/* benefits section css start*/
.benefitsbg {
  margin: 60px 0px 0px;
  padding: 50px;
  background-attachment: fixed;
  background-image: url("/public/images/industrymain/benifits.webp");
  background-position: center;
  background-size: cover;
}

.benefitsbg h1 {
  color: #fff8f5;
  text-align: center;
  font-size: 41px;
  font-weight: 500;
  line-height: 60px;
  text-transform: capitalize;
}

.benefitsbg p {
  color: #fff;
  text-align: center;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
}

/* benefits section css end*/
/* right section css end*/

/* contact section css start*/
.contactbg {
  margin-top: 30px;
  padding: 50px 50px 20px;
  border-radius: 20px;
  background: linear-gradient(
    100deg,
    #50316e -26.34%,
    #9a2b5d 47.96%,
    #df8935 119.93%
  );
}

.contacttext h1 {
  color: #fff8f5;
  font-size: 44px;
  font-weight: 500;
  line-height: 60px;
}

.contacttext p {
  color: #fff8f5;
  font-size: 19px;
  font-weight: 400;
  line-height: 160%;
}

.contacbtn {
  color: #fff8f5;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  border-radius: 1px;
  background: #c72966;
}

.contacimg {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.contacimg img {
  width: 75%;
  object-fit: cover;
}

.contac {
  display: flex;
}

/* contact section css end*/
/* role section css start*/
.role {
  margin-top: 40px;
}

.role h1 {
  color: #07242f;
  font-size: 39px;
  font-weight: 600;
  line-height: 49px;
  text-transform: capitalize;
}

.role p {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 29px;
}

.rolebg {
  margin: 10px 0px;
  display: flex;
  background: #f4f4f4;
  padding: 20px 0 10px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.roleimg {
  width: 50px;
  height: 50px;
}

.rolebg h1 {
  color: #121212;
  font-size: 21px;
  font-weight: 600;
}

/* =================================== **media query**=================================== */
@media (max-width: 767px) {
  .bannerbg {
    padding: 70px 0px;
  }

  .sidebg {
    margin-top: 0px !important;
  }

  .headingbg {
    height: 45px;
  }

  .headingbg h1 {
    padding-top: 0px;
    font-size: 31px;
  }

  .outsourcing h1,
  .typesservices h1,
  .steps h1,
  .role h1 {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
  }

  .herotext p,
  .typesservices p,
  .steps p,
  .role p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  .typesservices br,
  .steps br {
    display: none;
  }

  .herosection img {
    height: 300px;
    margin-bottom: 20px;
  }

  .typebox h2,
  .typebox2nd h2,
  .typebox3rd h2 {
    margin-top: 20px;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
  }

  .typeboxbg {
    padding: 40px 30px;
  }

  .typeboxbg p {
    font-size: 17px;
    line-height: 26px;
  }

  .outsourcing {
    padding-left: 0px;
  }

  .typebox,
  .typebox2nd,
  .typebox3rd {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benefitsbg,
  .contactbg {
    padding: 25px;
  }

  .benefitsbg h1 {
    font-size: 25px;
    line-height: 31px;
  }

  .benefitsbg p {
    font-size: 15px;
    line-height: 21px;
  }

  .contacttext {
    margin: 0px;
  }

  .contacttext h1 {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }

  .contacttext p {
    margin-top: 10px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .contacbtn {
    margin: auto;
    margin-bottom: 20px;
    display: block;
  }

  .contacimg {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bannerbg {
    padding: 70px 0px;
  }

  .headingbg {
    height: 45px;
  }

  .headingbg h1 {
    padding-top: 0px;
    font-size: 31px;
  }

  .outsourcing h1,
  .typesservices h1,
  .steps h1,
  .role h1 {
    font-size: 28px;
    line-height: 33px;
    text-align: center;
  }

  .steps h1 {
    line-height: 25px;
    margin: 8px 0;
    font-size: 20px;
  }

  .stepboxbg {
    padding: 22px 20px;
  }

  .stepboxbg img {
    margin: auto;
    display: block;
    height: 30px;
    width: 15px;
  }

  .steps p {
    margin: 0;
    font-size: 13px;
  }

  .steps {
    max-width: 400px !important;
    min-width: 400px !important;
  }

  .herotext p,
  .typesservices p,
  .steps p,
  .role p {
    font-size: 18px;
    text-align: center;
  }

  .typesservices br,
  .steps br {
    display: none;
  }

  .herosection img {
    height: 300px;
    margin-bottom: 20px;
  }

  .typebox h2,
  .typebox2nd h2,
  .typebox3rd h2 {
    margin-top: 20px;
    font-size: 33px;
    line-height: 35px;
    text-align: center;
  }

  .typeboxbg {
    padding: 40px 30px;
  }

  .typeboxbg p {
    font-size: 17px;
    line-height: 26px;
  }

  .outsourcing {
    padding-left: 0px;
  }

  .typebox,
  .typebox2nd,
  .typebox3rd {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benefitsbg,
  .contactbg {
    padding: 35px 25px;
  }

  .benefitsbg h1 {
    font-size: 25px;
    line-height: 31px;
  }

  .benefitsbg p {
    font-size: 15px;
    line-height: 21px;
  }

  .contacttext {
    margin: 0px;
  }

  .contacttext h1 {
    font-size: 34px;
    line-height: 35px;
    text-align: center;
  }

  .contacttext p {
    margin-top: 10px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .contacbtn {
    margin: auto;
    margin-bottom: 20px;
    display: block;
  }

  .contacimg {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  /* sider css */
  .sidebg {
    padding: 30px 0px;
  }

  .sideheading h6 {
    font-size: 20px;
  }

  .sideheading,
  .formboxpadding {
    padding: 0px 15px;
  }

  .listboxbg button:not(.collapsed) {
    padding: 15px;
    font-size: 11px !important;
  }

  .contacttext p {
    font-size: 14px;
    line-height: 140%;
  }

  .contacimg img {
    width: 90%;
    object-fit: cover;
  }

  .contacttext h1 {
    color: #fff8f5;
    font-size: 30px;

    font-weight: 500;
    line-height: 60px;
  }

  .listbodybg h1 {
    font-size: 11px;
  }

  .iconimg {
    width: 30px;
    height: 30px;
  }

  .bannerbg {
    padding: 100px 0px;
  }

  .outsourcing {
    padding-left: 0;
  }

  .typesservices br {
    display: none;
  }

  .outsourcing h1,
  .typesservices h1 {
    font-size: 30px;
  }

  .herotext p,
  .typesservices p {
    font-size: 17px;
    line-height: 25px;
  }

  .steps p,
  .role h1 {
    line-height: 21px;
    font-size: 15px;
  }

  .steps h1,
  .role h1 {
    font-size: 30px;
    line-height: 38px;
  }

  .stepboxbg {
    padding: 30px 24px;
  }

  .herosection img {
    height: 400px;
  }

  .typeboxbg {
    padding: 132px 30px;
  }

  .benefitsbg h1 {
    font-size: 35px;
    line-height: 41px;
  }

  .steps {
    max-width: 740px !important;
    min-width: 740px !important;
  }

  .stepboxbg img {
    height: 30px;
    width: 15px;
  }

  .typebox h2,
  .typebox2nd h2,
  .typebox3rd h2 {
    color: #fff;
    font-size: 26px;

    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .steps {
    max-width: 915px !important;
    min-width: 915px !important;
  }

  .listboxbg button:not(.collapsed) {
    padding: 15px 20px;
    font-size: 13px !important;
  }

  .outsourcing h1,
  .typesservices h1,
  .role h1,
  .contacttext h1 {
    font-size: 34px;
    line-height: 40px;
  }

  .herotext p,
  .typesservices p {
    font-size: 17px;
    line-height: 24px;
  }

  .steps p,
  .role p,
  .contacttext p {
    font-size: 15px;
    line-height: 22px;
  }

  .rolebg h1 {
    font-size: 19px;
  }

  .steps h1 {
    line-height: 30px;
    font-size: 20px;
  }

  .stepboxbg {
    padding: 30px 24px;
  }

  .herosection img {
    height: 310px;
  }

  .typeboxbg {
    padding: 145px 30px;
  }

  .benefitsbg h1 {
    font-size: 35px;
    line-height: 41px;
  }

  .sideheading,
  .headercolor {
    padding: 0 15px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .steps {
    max-width: 954px !important;
    min-width: 954px !important;
  }

  .listbox hr {
    width: 84%;
    margin-left: 15px;
  }

  .sideheading,
  .headercolor {
    padding: 0 15px;
  }

  .listboxbg button:not(.collapsed) {
    padding: 15px;
  }

  .outsourcing h1,
  .typesservices h1,
  .role h1,
  .contacttext h1 {
    font-size: 34px;
    line-height: 40px;
  }

  .herotext p,
  .typesservices p {
    font-size: 17px;
    line-height: 24px;
  }

  .rolebg h1 {
    font-size: 19px;
    line-height: 24px;
  }

  .steps p,
  .role p,
  .contacttext p {
    line-height: 24px;
    font-size: 17px;
  }

  .steps h1 {
    line-height: 30px;
    font-size: 20px;
  }

  .stepboxbg {
    padding: 30px 24px;
  }

  .herosection img {
    height: 310px;
  }

  .typeboxbg {
    padding: 145px 30px;
  }

  .benefitsbg h1 {
    font-size: 35px;
    line-height: 41px;
  }
}

@media (min-width: 1440px) and (max-width: 1450px) {
  .steps {
    max-width: 1038px !important;
    min-width: 1038px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1679px) {
  .steps {
    max-width: 1136px !important;
    min-width: 1136px !important;
  }

  .outsourcing h1,
  .typesservices h1 {
    font-size: 35px;
  }

  .herotext p,
  .typesservices p {
    font-size: 17px;
  }

  .steps p {
    font-size: 15px;
  }

  .steps h1 {
    line-height: 30px;
    font-size: 20px;
  }

  .stepboxbg {
    padding: 30px 24px;
  }

  .herosection img {
    height: 260px;
  }

  .typeboxbg {
    padding: 92px 30px;
  }

  .benefitsbg h1 {
    font-size: 35px;
    line-height: 41px;
  }
}

@media (min-width: 1680px) and (max-width: 1920px) {
  .steps {
    max-width: 1206px !important;
    min-width: 1194px !important;
  }

  .herosection img {
    height: 315px;
  }

  .typeboxbg {
    padding: 63px 30px;
  }
}

@media (min-width: 1920px) and (max-width: 2250px) {
  .steps {
    max-width: 1315px !important;
    min-width: 1310px !important;
  }
}
