/* banner section css start*/
.bannerbg {
  background-image: linear-gradient(
    265deg,
    #523578 1.03%,
    #d32864 51.26%,
    #f59433 93.99%
  );
  padding: 102px 0px;
}

.headingbg {
  background-image: url("/public/images/About/Services & About Us.png");
  height: 66px;
  background-size: contain;
  background-repeat: no-repeat;
}

.headingbg h1 {
  margin: 0px;
  padding-left: 5px;
  padding-top: 29px;
  color: #fff8f5;
  font-size: 48px;
  font-weight: 700;
  line-height: 40px;
  padding-top: 14px;
}

/* banner section css end*/

/* 
.bannerAbout {
  background: url(../../../public/images/About/bannerAbout.jpg) no-repeat;
  height: 335px;
  background-size: cover;
} */
.aboutcol img {
  width: 500px;
  height: 500px;
}
.aboutcontent h5 {
  color: #333333;
  font-weight: 600;
  font-size: 40px;
}
.aboutcontent p {
  color: #6c6c72;
  font-weight: 300;
  line-height: 31px;
  font-size: 20px;
}
.abouticon {
  background-color: white;
}
.aboutMain {
  background-color: #eeeeee;
  padding-top: 70px;
  padding-bottom: 200px;
}

.abouticon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 12px;
  box-shadow: 0px 0px 2px 3px #d7d7d7;
}

.mainOwner {
  background-color: #fff;
  width: 65%;
  /* margin-top: 50px; */
  margin-left: 7%;
  border-radius: 10px;
  position: absolute;
  top: -20%;
}
.ownerSection {
  padding: 50px 0px 20px 20px;
}

.ownerSection h5 {
  font-size: 40px;
  font-weight: 600;
}
.serviceBg {
  background: url(../../../public/images/About/About002.png) no-repeat;
  height: 600px;
  background-size: cover;
  position: relative;
}
.mainOwnerImg img {
  margin-top: -82px;
  height: 469px;
  transform: rotateY(180deg);
}
.ownerSection h6 {
  font-weight: 600;
  font-size: 18px;
}

.ownerSection p {
  font-size: 20px;
  font-weight: 400;
  color: #6c6c72;
}
.serviceBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.viIcon {
  width: 150px;
  height: 120px;
  margin-top: 4%;
}
.expert {
  /* background-color: #fff8f5; */
  background-image: linear-gradient(
    185deg,
    #523578 1.03%,
    #d32864 51.26%,
    #f59433 93.99%
  );
}
.team h2 {
  font-size: 40px;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}
.team p {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #6c6c72;
  color: #fff;
}
.sliderTeam img {
  width: 300px;
  height: 350px;
  position: relative;
}
.mainOwnerHide {
  display: none;
}
.teamMemberName h3 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
.teamMemberName h6 {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.teamMemberName {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0.2px rgb(170 166 166);
  bottom: 59px;
  left: 30px;
  padding: 8px 16px;
}
.sliderTeam {
  padding-bottom: 40px;
}

.contactForm {
  height: auto;
  background-size: cover;
  padding: 40px 60px;
  border-radius: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contactMail h5 {
  font-size: 45px;
  color: #333333;
  font-weight: 600;
}

.contactMail p {
  font-size: 20px;
  color: #6c6c72;
  margin-bottom: 30px;
  font-weight: 400;
}
.ico_get,
.ico_get2 {
  position: relative;
}
.ico_get span {
  position: absolute;
  color: red;
  font-size: 20px;
  left: 72px;
  top: 8px;
}

.email_star span {
  position: absolute;
  color: red;
  font-size: 20px;
  left: 115px;
  top: 8px;
}

.phone_star span {
  position: absolute;
  color: red;
  font-size: 20px;
  left: 72px;
  top: 8px;
}
.satarTextArea {
  position: relative;
}
.satarTextArea label {
  position: absolute;
  color: red;
  font-size: 20px;
  left: 210px !important;
  top: 7px;
}
.mainForm {
  padding: 14px 14px 14px 23px;
  border-radius: 0px;
  border-color: #fff;
  border-bottom: 1.5px solid #b5b5b5 !important;
}
/* .btn {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.btn .formbtn {
  background-color: #f59435;
  border: 0px !important;
  border-radius: 0px !important;
  padding: 12px 47px;
  margin-top: 25px;
}
.textareaForm {
  border-radius: 0px;
  border-color: #fff;
  border-bottom: 1.5px solid #b5b5b5 !important;
  padding: 15px 0px 0px 20px;
}
.getInToch {
  background-color: #fff;
}
.ico_get svg {
  position: absolute;
  top: 18px;
  left: -4px;
  z-index: 10;
}
.ico_get2 svg {
  position: absolute;
  top: 21px;
  left: 6px;
  z-index: 10;
}
.getIn_img img {
  width: 80%;
}
.getIn_img {
  text-align: right;
}
.findsectionbg {
  background: url(../../../public/images/home/findbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 20px;
  text-align: center;
  margin-top: 100px;
  border-radius: 30px;
}
.findtextarea h6 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  color: white;
  letter-spacing: 0em;
  text-align: center;
}
.bg {
  margin-top: 122px;
}
.findtextarea p {
  margin-top: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  color: #fff8f5;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 0.9;
}
.formbtn:hover {
  background-color: #c72966;
  transform: scale(1.07);
  transition: all 0.5s ease-in-out;
}
.findbtn {
  margin-top: 10px;
  background: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #c72966;
  padding: 15px 30px;
}
.findbtn:hover {
  background: #f0833b;
  border-color: #ef813b;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.featurrbox img,
.featurrbox1 img,
.featurrbox2 img,
.featurrbox3 img {
  width: 50px;
}
.featurrbox:hover,
.featurrbox1:hover,
.featurrbox2:hover,
.featurrbox3:hover {
  transform: scale(1.07);
  transition: all 0.5s ease-in-out;
  background: linear-gradient(
    137.51deg,
    #583477 -11.87%,
    #c42a66 47.28%,
    #f38d36 111.76%
  );
}
.featurrbox {
  background: #f59433;
  box-shadow: 0px 0px 8px 0.5px hsba(0, 0%, 0%, 0.25);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 10px;
}
.featurrbox h5,
.featurrbox1 h5,
.featurrbox2 h5,
.featurrbox3 h5 {
  font-size: 25px;
  color: #ededed;
  font-weight: 400;
  margin: 20px 0;
}

.featurrbox h5 span,
.featurrbox1 h5 span,
.featurrbox2 h5 span,
.featurrbox3 h5 span {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.featurrbox1 {
  background: #5f3376;
  box-shadow: 0px 0px 8px 0.5px hsba(0, 0%, 0%, 0.25);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 10px;
}
.featurrbox2 {
  background: #f7d394;
  box-shadow: 0px 0px 8px 0.5px hsba(0, 0%, 0%, 0.25);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 10px;
}
.featurrbox3 {
  background: #c72966;
  box-shadow: 0px 0px 8px 0.5px hsba(0, 0%, 0%, 0.25);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 10px;
}

@media (min-width: 1270px) and (max-width: 1376px) {
  .featurrbox h5 span,
  .featurrbox1 h5 span,
  .featurrbox2 h5 span,
  .featurrbox3 h5 span {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  .viIcon {
    width: 150px;
    height: 120px;
    margin-top: 11%;
  }
  .featurrbox,
  .featurrbox1,
  .featurrbox2,
  .featurrbox2,
  .featurrbox3 {
    padding: 5px;
  }
  .aboutcol img {
    width: 450px !important;
    height: 500px;
  }
  .serviceBg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ownerSection {
    padding: 7px 0px 20px 20px;
  }
  .serviceBg {
    height: 564px;
    background-size: contain;
    position: relative;
  }
  .getIn_img img {
    width: 98%;
  }
}

@media (min-width: 1000px) and (max-width: 1024px) {
  .sliderTeam img {
    width: 285px;
    height: 307px;
    position: relative;
  }
  .teamMemberName {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0.2px rgb(170 166 166);
    bottom: 121px !important;
    left: 3px !important;
    padding: 8px 16px;
  }
}
@media (min-width: 961px) and (max-width: 1024px) {
  .featurrbox h5,
  .featurrbox1 h5,
  .featurrbox2 h5,
  .featurrbox3 h5 {
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    line-height: 15px;
    margin: 5px 0px !important;
  }

  .aboutcol img {
    width: 100%;
    height: 500px;
  }
  .aboutcontent h5 {
    color: #333333;
    font-weight: 600;
    font-size: 28px;
  }
  .aboutcontent p {
    color: #6c6c72;
    font-weight: 300;
    line-height: 25px;
    font-size: 18px;
  }
  .ownerSection {
    padding: 14px 0px 20px 20px;
  }
  .ownerSection h5 {
    font-size: 28px;
    font-weight: 600;
  }
  .ownerSection p {
    font-size: 17px;
    font-weight: 400;
    color: #6c6c72;
  }
  .mainOwnerImg img {
    margin-top: -82px;
    height: 407px;
  }

  .serviceBg {
    height: 437px;
    background-size: cover;
    position: relative;
  }

  .viIcon {
    width: 150px;
    height: 120px;
    margin-top: 17%;
  }
  .sliderTeam img {
    width: 230px;
    height: 272px;
    position: relative;
  }
  .teamMemberName {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0.2px rgb(170 166 166);
    bottom: 94px;
    left: 24px;
    padding: 8px 16px;
  }
  .team h2 {
    font-size: 28px;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: 20px;
  }
  .team p {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
  .contactMail h5 {
    font-size: 35px;
    color: #333333;
  }
  .contactMail p {
    font-size: 18px;
    color: #6c6c72;
    margin-bottom: 30px;
    font-weight: 400;
  }
  .getIn_img img {
    width: 99%;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .btn {
    text-align: center;
  }

  .featurrbox,
  .featurrbox1,
  .featurrbox2 {
    margin-bottom: 10px;
  }
  .featurrbox h5 span,
  .featurrbox1 h5 span,
  .featurrbox2 h5 span,
  .featurrbox3 h5 span {
    font-size: 24px;
  }
  /* .aboutcol img {
    width: 100%;
    height: 500px;
  } */
  .aboutcol,
  .aboutcontent {
    text-align: center;
    margin-top: 25px;
  }
  .ownerSection h5 {
    font-size: 28px;
    font-weight: 600;
  }
  .ownerSection p {
    font-size: 16px;
    font-weight: 400;
    color: #6c6c72;
  }
  .mainOwner {
    background-color: #fff;
    width: 86%;
    /* margin-top: 50px; */
    margin-left: -1%;
    border-radius: 10px;
    position: absolute;
    top: -20%;
  }
  .mainOwnerImg img {
    margin-top: -82px;
    height: 321px;
  }
  .ownerSection {
    padding: 29px 2px 0px 20px;
  }
  .serviceBg {
    height: 415px;
    background-size: cover;
    position: relative;
  }
  .viIcon {
    width: 135px;
    height: 100px;
    margin-top: 30%;
  }
  .expert {
    background-color: #fff8f5;
    padding-top: 40px;
  }
  .aboutcontent h5 {
    color: #333333;
    font-weight: 600;
    font-size: 35px;
  }
  .team h2 {
    font-size: 28px;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: 20px;
  }
  .team p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #6c6c72;
  }
  .contactMail h5 {
    font-size: 28px;
    color: #333333;
    font-weight: 600;
  }
  .contactMail p {
    font-size: 16px;
    color: #6c6c72;
    margin-bottom: 30px;
    font-weight: 400;
  }
  .contactMail h5,
  .contactMail p {
    text-align: center;
  }
  .getIn_img {
    display: none;
  }
  .aboutcontent p {
    font-size: 16px;
  }
  .sliderTeam img {
    width: 301px;
    height: 276px;
    position: relative;
  }
  .teamMemberName {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0.2px rgb(170 166 166);
    bottom: 129px;
    left: 30px;
    padding: 8px 4px;
  }
  .teamMemberName h3 {
    font-weight: 600;
    font-size: 23px;
    text-align: center;
  }
  .teamMemberName h6 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .serviceBg {
    height: 696px;
    background-size: cover;
    position: relative;
  }
  .ownerSection {
    padding: 44px 10px 10px 10px;
  }
  .mainOwnerHide {
    display: block;
    text-align: center;
  }
  .mainOwnerHide img {
    width: 200px;
  }
  .ownerSection h6,
  .ownrDg {
    display: none;
  }
  .mainOwnerImg {
    display: none;
  }
  .mainOwnerHide h6 {
    margin-top: 15px;
  }

  .featurrbox h5 span,
  .featurrbox1 h5 span,
  .featurrbox2 h5 span,
  .featurrbox3 h5 span {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
  }
  .bannerAbout {
    height: 122px;
    background-size: cover;
  }
  .aboutcol img {
    width: 100%;
    height: 247px;
  }
  .aboutcontent h5 {
    color: #333333;
    font-weight: 600;
    font-size: 30px;
    margin-top: 30px;
    text-align: center;
  }
  .aboutcontent p {
    color: #6c6c72;
    font-weight: 300;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
  }
  .abouticon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 12px;
    box-shadow: 0px 0px 2px 3px #d7d7d7;
    margin-bottom: 10px;
  }
  /* .aboutMain {
  background-color: #eeeeee;
  padding-top: 67px;
  padding-bottom: 129px;
} */
  .mainOwner {
    background-color: #fff;
    width: 88%;
    /* margin-top: 50px; */
    margin-left: 2%;
    border-radius: 10px;
    position: absolute;
    top: -20%;
  }
  .ownerSection h5 {
    font-size: 27px;
    font-weight: 600;
    text-align: center;
  }
  .mainOwnerImg img {
    margin-top: -38px;
    height: 172px;
  }

  .mainOwner {
    background-color: #fff;
    width: 88%;
    /* margin-top: 50px; */
    margin-left: 2%;
    border-radius: 10px;
    position: absolute;
    top: -30%;
  }
  .viIcon {
    width: 144px;
    height: 120px;
    margin-top: 545px;
  }
  .team h2 {
    font-size: 22px;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 39px;
  }
  .team p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .aboutMain {
    background-color: #eeeeee;
    padding-top: 30px;
    padding-bottom: 200px;
  }
  .contactMail h5 {
    font-size: 30px;
    color: #333333;
    font-weight: 600;
    text-align: center;
  }
  .contactMail p {
    font-size: 16px;
    color: #6c6c72;
    margin-bottom: 23px;
    font-weight: 400;
    text-align: center;
  }
  .getIn_img img {
    width: 100%;
    margin-top: 39px;
  }
  .contactForm {
    height: auto;
    background-size: cover;
    padding: 40px 18px;
    border-radius: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .featurrbox,
  .featurrbox1,
  .featurrbox2 {
    margin-bottom: 20px;
  }

  .ownerSection p {
    font-size: 16px;
    font-weight: 400;
    color: #6c6c72;
    text-align: center;
    margin-bottom: 25px;
  }
  .ownerSection h6 {
    text-align: center;
  }
  .btn {
    text-align: center;
  }
  .getIn_img {
    display: none;
  }
}
