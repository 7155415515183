/* banner section css start*/
.bannerbg {
  background-image: linear-gradient(
    265deg,
    #523578 1.03%,
    #d32864 51.26%,
    #f59433 93.99%
  );
  padding: 150px 0px;
}

.headingbg {
  background-image: url("/public/images/contact-us/contactus.png");
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}

.headingbg h1 {
  margin: 0px;
  padding-top: 5px;
  color: #fff8f5;
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
}

/* banner section css end*/
.outsecsec {
  margin-top: 50px;
}

.outsecsec h5 {
  font-weight: 600;
  font-size: 45px;
  text-align: center;
}

.outsecsec p {
  font-size: 20px;
  text-align: center;
  color: #3c3c3c;
  margin-bottom: 50px;
}

.contactImg img {
  width: 95%;
}

.contactImg h1 {
  color: #c1363b;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
}

.contactImg h5 {
  color: #000;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
}

.contactImg p {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.contactForm {
  background: url(../../../public/images/contact-us/contactForm.jpg) no-repeat;
  height: auto;
  background-size: cover;
  padding: 40px 60px;
  border-radius: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contactMail h5 {
  font-size: 45px;
  text-align: center;
  color: #fff;
}

.contactMail p {
  font-size: 20px;
  text-align: center;
  color: #fff8f5;
  margin-bottom: 30px;
  font-weight: 400;
}

.mainForm {
  padding: 15px;
  border-radius: 0px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn .formbtn {
  background-color: #f59435;
  border: 0px !important;
  border-radius: 0px !important;
  padding: 12px 47px;
}

.textareaForm {
  border-radius: 0px;
}

@media (max-width: 767px) {
  .outsecsec h5,
  .contactMail h5 {
    font-size: 34px;
  }

  .headingbg h1 {
    padding-top: 0px;
    font-size: 34px;
  }

  .headingbg {
    height: 44px;
  }

  .bannerbg {
    padding: 70px 0;
  }

  .outsecsec br,
  .contactMail br {
    display: none;
  }

  .outsecsec p,
  .contactMail p {
    font-size: 17px;
  }

  .contactForm {
    padding: 40px 20px;
  }

  .contactImg img {
    width: 100%;
  }

  .contactImg h1,
  .contactImg h5,
  .contactImg p {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .outsecsec h5,
  .contactMail h5 {
    font-size: 34px;
  }

  .headingbg h1 {
    padding-top: 0px;
    font-size: 34px;
  }

  .headingbg {
    height: 44px;
  }

  .bannerbg {
    padding: 70px 0;
  }

  .outsecsec br,
  .contactMail br {
    display: none;
  }

  .outsecsec p,
  .contactMail p {
    font-size: 17px;
  }

  .contactForm {
    padding: 40px 40px;
  }

  .contactImg img {
    width: 100%;
  }

  .contactImg h1,
  .contactImg h5,
  .contactImg p {
    text-align: center;
  }
}
