body {
  background: #fff8f5 !important;
  font-family: poppins !important;
}

a {
  text-decoration: none !important;
}

.outsecsec h5 {
  text-align: center;
  font-size: 48px !important;
}

textarea:focus-visible {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.ico_get.form-control:focus > .ico_get span {
  display: none;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -2px;
  top: auto;
  left: 0;
  width: 100%;
}
.outsecsec p {
  text-align: center;
  font-size: 20px;
  color: #3c3c3c;
  margin-top: 35px;
}
a.nav-link.active-toggle.dropdown:focus {
  color: #f38d36 !important;
}
.listboxbg .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: rotate(90deg) !important;
  color: #f90f0f !important;
  font-size: 23px !important;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.our_expert_slider {
  padding-bottom: 59px;
}
/* service catelog swiper */
.service_catelog_swiper {
  height: auto;
  padding: 2rem 0;
  position: relative;

  .swiper-slide {
    width: 30rem;
    height: 28rem;
    position: relative;
  }

  .swiper-slide {
    /* background: #f59435; */
    box-shadow: -16.088056564331055px 16.088056564331055px 45.04655838012695px
      0px hsba(0, 0%, 0%, 0.25);
    color: #fff;
    padding: 20px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  }

  @media (max-width: 500px) {
    .swiper_container {
      height: 47rem;
    }

    .swiper-slide {
      width: 28rem !important;
      height: 36rem !important;
    }

    .swiper-slide img {
      width: auto !important;
      height: auto !important;
    }
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }

  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }

  @media (max-width: 990px) {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }

  @media (max-width: 450px) {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }

  @media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }

  @media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }

  .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }

  .slider-controler .slider-arrow::after {
    content: "";
  }

  .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }

  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
  }
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-slide.swiper-slide-visible {
  padding: 80px 40px;
}

.swiper-3d {
  perspective: 1302px !important;
}

/* service catelog swiper mobile */
.service_catelog_swiper_mobile {
  --swiper-navigation-size: 25px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: white;
}

/* whysyncdslider */
.our_expert_slider {
  .swiper,
  .swiper-container {
    height: 500px !important;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #f59435;
  }
}

.steps_slider {
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #c72966;
  }
}

.our_steps_slider {
  .swiper,
  swiper-container {
    height: 500px !important;
  }

  .swiper-slide {
    width: 445px !important;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #c72966;
  }
}

@media (max-width: 767px) {
  .our_steps_slider {
    .swiper,
    swiper-container {
      height: 500px !important;
    }

    .swiper-slide {
      width: 350px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .our_steps_slider {
    .swiper,
    swiper-container {
      height: 500px !important;
    }

    .swiper-slide {
      width: 370px !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .our_steps_slider {
    .swiper,
    swiper-container {
      height: 500px !important;
    }

    .swiper-slide {
      width: 340px !important;
    }
  }

  .hireMainAcc .accordion-button.collapsed {
    font-size: 14px !important;
    padding: 24px 10px;
  }

  .active > .page-link {
    margin-right: 14px !important;
    width: 37px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .our_steps_slider {
    .swiper,
    swiper-container {
      height: 500px !important;
    }

    .swiper-slide {
      width: 410px !important;
    }
  }

  .hireMainAcc .accordion-button.collapsed {
    font-size: 17px !important;
    padding: 24px 10px;
  }

  .active > .page-link {
    margin-right: 14px !important;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .active > .page-link {
    margin-right: 14px !important;
  }

  .hireMainAcc .accordion-button.collapsed {
    font-size: 18px !important;
    padding: 24px 10px;
  }

  .our_steps_slider {
    .swiper,
    swiper-container {
      height: 500px !important;
    }

    .swiper-slide {
      width: 410px !important;
    }
  }
}

@media (min-width: 1600px) and (max-width: 2550px) {
  .container {
    max-width: 1520px !important;
  }
}

/* faq css */
.faqaccordion {
  .accordion-button.collapsed {
    background: #eeeeee !important;
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
    border-radius: 0px !important;
    font-size: 20px;
  }
}

.accordion-item {
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
}

@media (max-width: 767px) {
  button.accordion-button.collapsed {
    font-size: 16px;
  }
}

@media (min-width: 768) and (max-width: 1024px) {
  button.accordion-button.collapsed {
    font-size: 18px;
  }

  button.accordion-button.collapsed {
    font-size: 20px;
  }
}

a.footer {
  font-size: 20px !important;
  color: #fff !important;
  text-decoration: none !important;
}

/* main industries page  */
.hireMainAcc {
  .accordion-button.collapsed {
    background: #fff8f5 !important;
    border: none !important;
    margin-bottom: 50px;
    border-radius: 0px !important;
    font-size: 20px;
    border-radius: 40px !important;
    box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.25) !important;
    color: #6c6c72;
    font-size: 20px;
    font-weight: 600;
    padding: 24px 15px;
    opacity: 1;
  }

  .accordion-button:not(.collapsed) {
    color: #6c6c72;
    font-size: 20px;
    font-weight: 600;
    background: #fff8f5 !important;
    border: none !important;
    margin-bottom: 20px;
    border-radius: 0px !important;
    font-size: 20px;
    border-radius: 40px !important;
    box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.25) !important;
    padding: 24px 15px;
  }

  .accordion-body {
    padding: var(--bs-accordion-body-padding-y)
      var(--bs-accordion-body-padding-x);
    background-color: #fff8f5;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .accordion-button {
    color: #6c6c72;
    font-size: 20px;
    font-weight: 600;
  }
}

/* main industries page  */
/* pagination css */
.active > .page-link {
  display: flex;
  background: #f59433 !important;
  border: none;
  margin-right: 24px;
  width: 50px;
  min-height: 40px;
  align-items: center;
  justify-content: center;
}

@media (min-width: 320px) and (max-width: 560px) {
  .hireMainAcc .accordion-button.collapsed {
    background: #fff8f5 !important;
    border: none !important;
    margin-bottom: 18px;
    border-radius: 0px !important;
    font-size: 20px;
    border-radius: 40px !important;
    box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.25) !important;
    color: #6c6c72;
    font-size: 14px;
    font-weight: 600;
    padding: 18px 15px;
    opacity: 1;
  }
}

/* ================= menu Drop Down css===========  */

.dropdown:hover > .dropdown-menu {
  display: block;
}

.headDropDown {
  .dropdown-menu.dropdown-menu-dark {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 1050px;
    padding: 30px;
    left: -444px;
  }

  .dropdown-menu.dropdown-menu-dark span {
    color: #eb7441;
    font-size: 40px;
    margin-bottom: 44px;
    line-height: 0;
    position: absolute;
    top: 21px;
  }

  .dropdown-menu.dropdown-menu-dark {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 1050px;
    padding: 30px;
    left: -444px;
  }

  .nav-link:focus,
  .nav-link:hover {
    color: black;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    color: #ffffff;
    background: #f59433;
  }

  /* .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  } */

  .card {
    border: none;
  }

  .card-header {
    background-color: #fff;
    border: none;
  }

  .card-header button {
    border: none;
    background-color: #fff;
    color: #5f3476;
    font-size: 16px;
    font-weight: 600;
  }

  .menudata p {
    font-weight: 500;
    font-size: 13px;
  }

  ::marker {
    color: #f59433;
  }

  .card-header:hover span {
    transform: rotate(90deg);
    transition: 0.8s ease;
    margin-left: 5px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
}

.openbtn {
  background-color: #c72966;
  border: none;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  padding: 5px 18px;
}

button.openbtn {
  position: absolute;
  right: 0;
}

.sidepanel {
  background: linear-gradient(
    110deg,
    #583477 -11.87%,
    #c42a66 74.28%,
    #f38d36 111.76%
  );
  height: 100%;
  left: 0;
  overflow-x: hidden;
  padding-top: 60px;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: 1;
  opacity: 0.94;
}

.closebtn {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 53px;
  margin-left: 50px;
  color: #fff;
}

div#mySidepanel a {
  color: #fff !important;
  text-align: center;
  margin-left: 20px;
  font-size: 21px;
  text-align: left;
  font-weight: 600;
}

@media (min-width: 760px) and (max-width: 991px) {
  .dropdown-menu {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 764px !important;
    padding: 30px;
    /* left: -452px; */
  }

  .openbtn {
    background-color: #c72966;
    border: none;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 20px;
    padding: 8px 24px;
    font-size: 27px;
  }
  .mobileActive {
    color: #ffffff;
    background: #ffffff00 !important;
  }

  .dropdown-menu-dark {
    color: #5f3476 !important;
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    text-align: left;
    text-transform: uppercase;
  }

  .card-header button {
    border: none;
    background-color: #fff;
    color: #5f3476;
    font-size: 14px !important;
    font-weight: 600;
  }
}

@media (min-width: 170px) and (max-width: 560px) {
  .nav {
    text-align: center !important;
    display: block !important;
  }
  .mobileActive {
    color: #ffffff;
    background: #ffffff00 !important;
  }

  .dropdown-menu {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 300px !important;
    padding: 30px;
    left: 0px !important;
  }

  .nav-link {
    color: #000;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 24px;
  }

  .card-header button {
    font-size: 12px !important;
    font-weight: 600;
    text-align: left;
  }

  .dropdown-menu-dark {
    color: #5f3476 !important;
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: 600;
  }

  .card-header span {
    color: #eb7441;
    font-size: 40px;
    margin-bottom: 44px;
    line-height: 0;
    position: absolute;
    top: 21px;
    left: 0px;
    transform: rotateY(182deg);
  }

  .card-header:hover span {
    transform: rotate(180x);
    transition: 0.8s ease;
    margin-left: 5px;
  }
}

/* ================= menu Drop Down css===========  */
